.MemePrompt {
  padding: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 800px) {
  .MemePrompt {
    flex-direction: row;
    gap: 5vw;
  }
}

.MemePromptInputs {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: flex-start;
}

.MemePromptInputItem {
  margin-bottom: 1em;
}

.MemePromptInput {
  composes: AppInput from "../../App.module.css";
  /* display: flex;
  width: 70vw; */
}

.MemePromptSubmitButton {
  composes: AppButton from "../../App.module.css";
  padding-left: 2em;
  padding-right: 2em;
}

.MemeRenderer {
  /* max-width: 700px;
  width: 80vw;
  height: 80vw; */
}
