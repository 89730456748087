@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

html, body {
  margin: 0;
  overflow-x: hidden;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --background: #f7faf1;
  --background-variant: #c9b8d3;
  --background-input: #c5c5c5;
  --background-image: url('images/background-light.png');
  --text-primary: #2d2a32;
  --text-secondary: royalblue;
  --accent: #63a375;
  --border-radius: 25px;
}

[data-theme='dark'] {
  --background: #2d2a32;
  --background-variant: #4f4958;
  --background-input: #4f4958;
  --background-image: url('images/background-dark.png');
  --text-primary: #fafdf6;
  --text-secondary: grey;
  --accent: #63a375;
}