/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Anton:wght@400&display=swap'); */


.MemeRenderer {
  max-width: 700px;
  width: 80vw;
  height: 80vw;
}

.MemeRendererCanvasContainer {

}

.MemeRendererSvg {

}

.MemeRendererCaption {
  
}