.ButtonPrompt {
  padding: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ButtonPromptSubmitButton {
  composes: AppButton from "../../App.module.css";
  display: flex;
  margin: 3em;
  padding-left: 2em;
  padding-right: 2em;
}