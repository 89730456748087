.SongPrompt {
  padding: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
}

.SongPromptSkipButton {
  composes: AppButton from "../../App.module.css";
  padding: 0.8em;
}

.SongPromptInput {
  composes: AppInput from "../../App.module.css";
  display: flex;
  width: 70vw;
}

.SongPromptResults {
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  width: 70vw;
}

.SongPromptResult {
  padding-bottom: 1em;
}

.SongPromptResultButton {
  composes: AppButton from "../../App.module.css";
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.SongPromptResultArtwork {
  height: 10vh;
}

.SongPromptResultDetails {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
}

.SongPromptResultDetailsTitle {
  flex: 1;
  margin: 0;
  margin-left: 0.5em;
  padding: 0;
  text-align: left;
  font-weight: bold;
  font-size: min(4vw, 1.2em);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}

.SongPromptResultDetailsArtists {
  flex: 1;
  margin: 0;
  margin-left: 0.5em;
  text-align: left;
  font-size: min(4vw, 1.2em);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}