.Error {
  background-color: red;
  color: var(--text-primary);
  border-radius: var(--border-radius);
  padding: 1em;
  display: flex;
  position: fixed;
  bottom: 10vh;
  left: 5vw;
  right: 5vw;
  opacity: 90%;
}

.Error.show {
  display: inherit;
}

.Error.hide {
  transition: opacity 0.5s ease-out;
  opacity: 0;
}