.App {
  color: var(--text-primary);
  background-color: var(--background);
  font-size: large;
  font-weight: bold;
  display: flex; 
  flex-direction: column;
  height: calc(var(--vh) * 100);
  transition: all .5s;
}

.AppHeader {
  background-color: var(--background-variant);
  height: calc(var(--vh) * 12);
  padding: 1vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  transition: height .5s;
}

.AppMain {
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  background-image: var(--background-image);
  background-repeat: repeat;
}

.AppFooter {
  margin-top:auto;
  background-color: var(--background-variant);
}

.AppButton {
  color: var(--text-primary);
  background-color: var(--accent);
  padding: 0.5em;
  border: none;
  border-radius: var(--border-radius);
  font-size: calc(10px + 2vmin);
}

.AppInput {
  color: var(--text-primary);
  background-color: var(--background-input);
  padding: 0.8em;
  margin: 0.5em;
  font-size: large;
  font-weight: 500;
  /* border: 1px solid var(--accent); */
  border: none;
  border-radius: var(--border-radius);
}

.AppThemeButton {
  composes: AppButton;
  margin-left: auto;
  float: right;
  align-self: flex-start;
  background-color: transparent;
  font-size: calc(10px + 1vmin);
}

.AppHeaderTitle {
  color: var(--text-primary);
  display: flex;
  align-items: center;
}