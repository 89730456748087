.TextPrompt {
  padding: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.TextPromptField {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0.5em;
}

.TextPromptLabel {

}

.TextPromptInput {
  composes: AppInput from "../../App.module.css";
  display: flex;
  width: 70vw;
}

.TextPromptSubmitButton {
  composes: AppButton from "../../App.module.css";
  display: flex;
  margin: 1em;
  padding-left: 2em;
  padding-right: 2em;
}