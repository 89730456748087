/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Anton:wght@400&display=swap'); */

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ImageSelectionControls {
  display: flex;
  column-gap: 1em;
  align-items: center;
  padding: 1em;
}

input[type="file"] {
  display: none;
}

.ImageChooseButton {
  composes: AppButton from "../../App.module.css";
}

.ImageUploadButton {
  composes: AppButton from "../../App.module.css";
}

.EditControls {
  display: flex;
  column-gap: 1em;
  align-items: center;
  padding: 1em;
}

.AppCaptionButton {
  composes: AppButton from "../../App.module.css";
}
