.MultiChoicePrompt {
  padding: 4vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.MultiChoicePromptOptions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  padding: 0;
}

.MultiChoicePromptLabel {
  font-size: calc(10px + 4vmin);
  text-align: center;
}

.MultiChoicePromptItem {
  /* flex: 1; */
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin: 2vw;
}

.MultiChoicePromptButton {
  composes: AppButton from "../../App.module.css";
  margin: 0;
  padding: 0;
  padding-left: 2em;
  padding-right: 2em;
  max-width: 100vh;
  min-height: 3.4em;
  white-space: pre-line;
}

.MultiChoicePromptButton pre {
  word-wrap: normal;
  white-space: pre-wrap;
}