.Prompts {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2vh;
}

.PromptsSubmissionOverlay {
  position: absolute;
  left: 0px;
  top: 0px; 
  bottom: 0px;
  right: 0px;
  display: none;
}